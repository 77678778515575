import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const Edit_user = ({
    showEdit,
    setShowEdit,
    successMessage,
    errorMessage,
    selectedUserId,
    user,
    fetchAccessToken,
    setRefresh,
    refresh
}) => {
    const [username, setUsername] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');

    const selectedUser = user.find((item) => item.id === selectedUserId);

    useEffect(() => {
        if (selectedUser) {
            setUsername(selectedUser.username || '');
            setRole(selectedUser.roles || '');
        }
    }, [selectedUser]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confPassword) {
            errorMessage('Password and Confirm Password do not match');
            return;
        }

        const URL = process.env.REACT_APP_API_URL;
        const token_return = await fetchAccessToken();

        const data = {};
        if (username !== selectedUser.username) {
            data.username = username;
        }
        if (role !== selectedUser.roles) {
            data.roles = role;
        }
        if (password !== selectedUser.password) {
            data.password = password;
        }

        try {
            const response = await axios.patch(
                `${URL}/api/v1/user/edit/${selectedUserId}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token_return}`,
                    },
                }
            );

            const message = response.data.message;
            successMessage(message);
            setShowEdit(false);
            setRefresh(refresh + 1);
            setPassword('');
            setConfPassword('');
            setUsername('');
            setRole('');
        } catch (error) {
            const error_message = error.response.data.message;
            errorMessage(error_message);
        }
    };

    return (
        <Modal
            show={showEdit}
            onHide={() => setShowEdit(false)}
            centered
            style={{ zIndex: 9999 }}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit {selectedUser && selectedUser.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            name="username"
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            name="password"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            name="confPassword"
                            type="password"
                            placeholder="Confirm Password"
                            value={confPassword}
                            onChange={(e) => setConfPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Role</Form.Label>
                        <Form.Select
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            aria-label="Role"
                        >
                            <option>Role</option>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                            <option value="owner">Owner</option>
                        </Form.Select>
                    </Form.Group>
                    <Button
                        type="submit"
                        className="btn btn-primary w-50 text-center"
                        variant="outline-info"
                        style={{ borderRadius: 20 }}
                    >
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default Edit_user;
