import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Row, Button, Col, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import { format } from 'date-fns';
import History_view_modal from "./modal/history_view_modal";
import Csv_history_stok from "./csv_history_stok";


const HistoryStok = () => {
    const URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [showCsv, setShowCsv] = useState(false);



    const data = {
        columns: [
            {
                label: "No",
                field: "no",
                sort: "asc",
                width: 150,
            },
            // {
            //     label: "User",
            //     field: "user",
            //     sort: "asc",
            //     width: 270,
            // },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 200,
            },
            {
                label: "Nama",
                field: "nama",
                sort: "asc",
                width: 200,
            },
            {
                label: "Warna",
                field: "warna",
                sort: "asc",
                width: 100,
            },
            {
                label: "Barcode",
                field: "barcode",
                sort: "asc",
                width: 150,
            },
            {
                label: "Stok",
                field: "stok",
                sort: "asc",
                width: 100,
            },
            {
                label: "Tanggal",
                field: "tanggal",
                sort: "asc",
                width: 100,
            },
            {
                label: "View",
                field: "view",
                sort: "asc",
                width: 100,
            },
        ],
        rows: history.map((item, index, historyArray) => {

            return {
                no: index + 1,
                action: item.description,
                nama: item.name,
                warna: item.color,
                barcode: item.barcode,
                stok: item.stok,
                tanggal: format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm'),

                view: (
                    <Button size='sm' variant="info" onClick={() => showmodal(item.id)}>
                        View
                    </Button>
                )
            }
        })

    };


    const showmodal = (id) => {
        setSelectedProductId(id);
        setShow(true);
    }

    const handleInfoClick = (id) => {
        setSelectedProductId(id);
        setShowCsv(true);
    };

    const successMessage = (success_message) => {
        toast.success(success_message, {
            position: toast.POSITION.TOP_RIGHT
        });
    };
    const errorMessage = (error_Message) => {
        toast.error(error_Message, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const fetchAccessToken = async () => {
        try {
            const response = await axios.post(`${URL}/api/v1/token/`);
            return response.data.accessToken;
        } catch (error) {
            setError("Failed to fetch access token. Redirecting...");
            navigate("/login");
        }
    };

    const fetchHistory = async () => {
        const URL = process.env.REACT_APP_API_URL;
        const token = await fetchAccessToken();
        try {
            const response = await axios.get(`${URL}/api/v1/history/stok`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data.data;
            setHistory(data);
            setLoading(false);
        } catch (error) {
            setError("Failed to fetch history data. Redirecting...");
        }
    };


    useEffect(() => {
        fetchHistory();
    }, []);

    useEffect(() => {
        if (loading) {
            loadingbar();
        }
    }, [loading])

    function loadingbar() {
        return (
            <div className="d-flex justify-content-center align-items-center" style={styles.container}>
                <Spinner animation="border" />
            </div>)

    }

    const styles = {
        container: {
          height: '100vh', // Full height of the viewport
        },
      };


    return (
        <>

            <Row className="container mt-5">
                <ToastContainer />
                {loading ? (
                    loadingbar()
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <div>
                        <Row>
                            <Col>
                                <Button size='sm' variant="info" onClick={() => handleInfoClick(null)}>
                                    Csv
                                </Button>
                            </Col>

                        </Row>
                        <MDBDataTable
                            striped
                            bordered
                            small
                            data={data}
                            responsive
                        />
                    </div>
                )}
            </Row>

            <History_view_modal show={show} setShow={setShow} selectedProductId={selectedProductId} setSelectedProductId={setSelectedProductId} fetchAccessToken={fetchAccessToken} successMessage={successMessage} errorMessage={errorMessage} />
            <Csv_history_stok fetchAccessToken={fetchAccessToken} showCsv={showCsv} setShowCsv={setShowCsv} successMessage={successMessage} errorMessage={errorMessage} />
        </>
    )
}

export default HistoryStok
