import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import logo from '../images/logo.png';

const Navbar = () => {
    const navigate = useNavigate();
    const URL = process.env.REACT_APP_API_URL;
    const [role, setRole] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        fetchAccessToken();
    }, []);

    const fetchAccessToken = async () => {
        try {
            const response = await axios.post(`${URL}/api/v1/token/`);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.permissions[0]);
        } catch (error) {
            navigate("/login");
        }
    };

    const Logout = async () => {
        try {
            await axios.delete(`${URL}/api/v1/logout`);
            navigate("/login");
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <nav className="navbar is-light" role="navigation" aria-label="main navigation">
            <div className="container">
                <div className="navbar-brand">
                    <a className="navbar-item">
                        <img width="70" height="28" alt="logo" src={logo} />
                    </a>

                    <a
                        role="button"
                        className={`navbar-burger burger ${isMenuOpen ? 'is-active' : ''}`}
                        aria-label="menu"
                        aria-expanded={isMenuOpen ? 'true' : 'false'}
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div className={`navbar-menu ${isMenuOpen ? 'is-active' : ''}`}>
                    <div className="navbar-start">
                        <a href="/product" className="navbar-item">
                            Product
                        </a>
                        {role === "owner" && (
                            <a href="/user" className="navbar-item">
                                User
                            </a>
                        )}
                        {(role === "admin" || role === "owner") && (
                            <a href="/history" className="navbar-item">
                                History
                            </a>
                        )}
                        <a href="/historystok" className="navbar-item">
                            History Stok
                        </a>
                    </div>

                    <div className="navbar-end">
                        <div className="navbar-item">
                            <div className="buttons">
                                <button onClick={Logout} className="button is-light">
                                    Log Out
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
