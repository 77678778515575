import {  Routes, Route, BrowserRouter } from "react-router-dom";
import Navbar from "./components/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./components/login";
import Product from "./components/product";
import User from "./components/user";
import History from "./components/history/history";
import HistoryStok from "./components/history/history_stok";
import Dashboard from "./components/dashboard";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login/>} />
        <Route path='*' element={<AppWithNavbar />} />
      </Routes>
    </BrowserRouter>
  );
}

function AppWithNavbar() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' element={< Dashboard/>} />
        <Route path='/product' element={<Product/>} />
        <Route path='/user' element={<User/>} />
        <Route path='/history' element={<History/>} />
        <Route path='/historystok' element={<HistoryStok/>} />

      </Routes>
    </>
  );
}

export default App;
