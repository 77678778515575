import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const navigate = useNavigate();


    const move = () =>{
        navigate("/product");
    }

    useEffect(() => {
        move();
    }
    , [])

  return (
    <div>
      
    </div>
  )
}

export default Dashboard
