import React, { useEffect, useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import axios from 'axios';
import { CSVLink } from "react-csv";
import { set } from 'date-fns';



const Csv_product = ({ showCsv, setShowCsv, fetchAccessToken, errorMessage, successMessage }) => {

    const [dibawah1, setDibawah1] = useState(false);
    const [data, setData] = useState([]);
    const [filename, setFilename] = useState('');
    const [stok, setStok] = useState(false);
    const [price_modal, setPrice_modal] = useState(false);
    const [price_dos, setPrice_dos] = useState(false);
    const [price_lusin, setPrice_lusin] = useState(false);
    const [price_grosir, setPrice_grosir] = useState(false);
    const [name, setName] = useState(false);
    const [color, setColor] = useState(false);
    const [barcode, setBarcode] = useState(false);
    const [success, setSuccess] = useState(false);




    const generateHeaders = () => {
        const headers = [
          { label: 'Name', key: 'name', checked: name },
          { label: 'Color', key: 'color', checked: color },
          { label: 'Barcode', key: 'barcode', checked: barcode },
          { label: 'Stok', key: 'stok', checked: stok },
          { label: 'Price Modal', key: 'price_modal', checked: price_modal },
          { label: 'Price Dos', key: 'price_dos', checked: price_dos },
          { label: 'Price Lusin', key: 'price_lusin', checked: price_lusin },
          { label: 'Price Grosir', key: 'price_grosir', checked: price_grosir },
        ];
    
        return headers.filter((header) => header.checked);
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const URL = process.env.REACT_APP_API_URL;
        const token_return = await fetchAccessToken();

        try {
            const response = await axios.post(`${URL}/api/v1/product/tocsv`, {
                dibawah1,
                name,
                color,
                barcode,
                stok,
                price_modal,
                price_dos,
                price_lusin,
                price_grosir

            },
                {
                    headers: {
                        Authorization: `Bearer ${token_return}`,
                    },
                });

            const message = response.data.message;
            const datares = response.data.data;
            setFilename(response.data.fileName);
            setSuccess(true);
            setData(datares);
            successMessage(message);


        } catch (error) {
            const error_message = error.response.data.message;
            errorMessage(error_message);
        }
    }

    useEffect(() => {
        if (success > 0) {
            document.getElementById("csv-download-link").click();
            setSuccess(false);
        }
    }, [success])


    return (
        <Modal
            show={showCsv}
            onHide={() => setShowCsv(false)}
            centered
            style={{ zIndex: 9999 }}
            size="lg">

            <Modal.Header closeButton>
                <Modal.Title>
                    Export CSV
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mx-3 mb-3" controlId="name">
                    <Form.Check 
                        type="checkbox"
                        label="Nama"
                        onChange={(e)=>setName(e.target.checked)}
                    />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="color">
                    <Form.Check 
                        type="checkbox"
                        label="Color"
                        onChange={(e)=>setColor(e.target.checked)}
                    />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="barcode">
                    <Form.Check 
                        type="checkbox"
                        label="Barcode"
                        onChange={(e)=>setBarcode(e.target.checked)}
                    />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="stok">
                    <Form.Check 
                        type="checkbox"
                        label="Stok"
                        onChange={(e)=>setStok(e.target.checked)}
                    />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="price_modal">
                    <Form.Check 
                        type="checkbox"
                        label="Price Modal"
                        onChange={(e)=>setPrice_modal(e.target.checked)}
                    />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="price_dos">
                    <Form.Check 
                        type="checkbox"
                        label="Price Dos"
                        onChange={(e)=>setPrice_dos(e.target.checked)}
                    />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="price_lusin">
                    <Form.Check 
                        type="checkbox"
                        label="Price Lusin"
                        onChange={(e)=>setPrice_lusin(e.target.checked)}
                    />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="price_grosir">
                    <Form.Check 
                        type="checkbox"
                        label="Price Grosir"
                        onChange={(e)=>setPrice_grosir(e.target.checked)}
                    />
                    </Form.Group>
                    <hr/>

                    <Form.Group className="mx-5 mb-3" controlId="price_modal">
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Produk yang tidak ada stok"
                            checked={dibawah1}
                            onChange={(e) => setDibawah1(e.target.checked)} 

                        />
                    </Form.Group>

                    <Button type="submit" className="btn btn-primary w-50 text-center" variant="outline-info" style={{ borderRadius: 20 }}>
                        Submit
                    </Button>

                </Form>
                <CSVLink
                    id="csv-download-link"
                    data={data}
                    headers={generateHeaders()}
                    filename={filename}
                    separator={";"}
                    hidden>
                    Download
                </CSVLink>
            </Modal.Body>

        </Modal>
    )
}


export default Csv_product
