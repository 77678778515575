import React,{useState} from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import axios from 'axios';


const Add_product = ({ showAdd, setShowAdd, fetchAccessToken, successMessage, errorMessage, refresh, setRefresh, role  }) => {
    const [name, setName] = useState('');
    const [color, setColor] = useState('');
    const [barcode, setBarcode] = useState('');
    const [stok, setStok] = useState(0);
    const [price_modal, setPrice_modal] = useState(0);
    const [price_dos, setPrice_dos] = useState(0);
    const [price_lusin, setPrice_lusin] = useState(0);
    const [price_grosir, setPrice_grosir] = useState(0);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const URL = process.env.REACT_APP_API_URL;
        const token_return = await fetchAccessToken();


        try {
            const response = await axios.post(`${URL}/api/v1/product/add`, {
                name,
                color,
                barcode,
                stok,
                price_modal,
                price_dos,
                price_lusin,
                price_grosir
            },
                {
                    headers: {
                        Authorization: `Bearer ${token_return}`,
                    },
                });

            const message = response.data.message;
            successMessage(message);
            setShowAdd(false);
            setName('');
            setColor('');
            setBarcode('');
            setStok('');
            setPrice_modal(0);
            setPrice_dos(0);
            setPrice_lusin(0);
            setPrice_grosir(0);
            setRefresh(refresh + 1);


        } catch (error) {
            const error_message = error.response.data.message;
            errorMessage(error_message);
        }
    };


    return (
        <Modal
            show={showAdd}
            onHide={() => setShowAdd(false)}
            centered
            style={{ zIndex: 9999 }}
            size="lg">

            <Modal.Header closeButton>
                <Modal.Title>
                    Add Product
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}  >
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Nama</Form.Label>
                        <Form.Control name="name" type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="color">
                        <Form.Label>Warna</Form.Label>
                        <Form.Control name="color" type="text" placeholder="Color" value={color} onChange={(e) => setColor(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="stok">
                        <Form.Label>Stok</Form.Label>
                        <Form.Control name="stok" type="number" placeholder="Stok" value={stok} onChange={(e) => setStok(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="price_modal">
                        <Form.Label>Harga Modal</Form.Label>
                        <Form.Control name="price_modal" type="number" placeholder="Harga Modal" value={price_modal} onChange={(e) => setPrice_modal(e.target.value)} />
                    </Form.Group>
                    {role === "owner" && (
                        <Form.Group className="mb-3" controlId="price_dos">
                        <Form.Label>Harga Dos</Form.Label>
                        <Form.Control name="price_dos" type="number" placeholder="Harga Dos" value={price_dos} onChange={(e) => setPrice_dos(e.target.value)} />
                    </Form.Group>
                    )}
                    {role === "owner" && (
                    <Form.Group className="mb-3" controlId="price_lusin">
                        <Form.Label>Harga Lusin</Form.Label>
                        <Form.Control name="price_lusin" type="number" placeholder="Harga Lusin" value={price_lusin} onChange={(e) => setPrice_lusin(e.target.value)} />
                    </Form.Group>
                    )}
                    {role === "owner" && (
                    <Form.Group className="mb-3" controlId="price_grosir">
                        <Form.Label>Harga Grosir</Form.Label>
                        <Form.Control name="price_grosir" type="number" placeholder="Harga Grosir" value={price_grosir} onChange={(e) => setPrice_grosir(e.target.value)} />
                    </Form.Group>    
                    )}
                    
                    <Form.Group className="mb-3" controlId="barcode">
                        <Form.Label>Barcode</Form.Label>
                        <Form.Control name="barcode" type="text" placeholder="Barcode" value={barcode} onChange={(e) => setBarcode(e.target.value)} />
                    </Form.Group>
                    <Button type="submit" className="btn btn-primary w-50 text-center" variant="outline-info" style={{ borderRadius: 20 }}>
                        Submit
                    </Button>
                </Form>
            </Modal.Body>

        </Modal>
    )
}

export default Add_product
