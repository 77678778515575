import React from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { Button } from 'react-bootstrap';

const Delete_user = ({ showDelete, setShowDelete, successMessage, errorMessage, fetchAccessToken, selectedUserId, user, setRefresh, refresh}) => {

    const handleClose = () => {
        setShowDelete(false);
    };
    

    const handleDelete = async (e) => {
        e.preventDefault();
        const token_return = await fetchAccessToken();
        try {
            const URL = process.env.REACT_APP_API_URL;
            const response = await axios.delete(`${URL}/api/v1/user/delete/${selectedUserId}`, {
                headers: {
                    Authorization: `Bearer ${token_return}`,
                },
            });
            const message = response.data.message;
            successMessage(message);
            setShowDelete(false);
            setRefresh(refresh + 1);
        } catch (error) {
            const error_message = error.response.data.message;
            errorMessage(error_message);
        }
    }

    return (
        <Modal
            show={showDelete}
            onHide={() => setShowDelete(false)}
            centered
            style={{ zIndex: 9999 }}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title >
                    Delete{" "}
                    {selectedUserId &&
                        user.find((item) => item.id === selectedUserId)?.username}
                </Modal.Title>
            </Modal.Header >
            <Modal.Body>
                <p>Yakin ingin menghapus user ini?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Batal
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Hapus
                </Button>
            </Modal.Footer>

        </Modal>
    )
}

export default Delete_user
