import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Spinner } from 'react-bootstrap'
import axios from 'axios';
import { CSVLink } from "react-csv";



const Csv_history_stok = ({ showCsv, setShowCsv, fetchAccessToken, errorMessage, successMessage }) => {
    const [data, setData] = useState([]);
    const [filename, setFilename] = useState('');
    const [stok, setStok] = useState(false);
    const [stoknow, setStoknow] = useState(false);
    const [action, setAction] = useState(false);
    const [date, setDate] = useState(false);
    const [datefrom, setDatefrom] = useState('');
    const [dateuntil, setDateuntil] = useState('');
    const [name, setName] = useState(false);
    const [color, setColor] = useState(false);
    const [barcode, setBarcode] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);




    const generateHeaders = () => {
        const headers = [
            { label: 'Name', key: 'name', checked: name },
            { label: 'Color', key: 'color', checked: color },
            { label: 'Barcode', key: 'barcode', checked: barcode },
            { label: 'Stok', key: 'stok', checked: stok },
            { label: 'Stok Before', key: 'stoknow', checked: stoknow },
            { label: 'Action', key: 'action', checked: action },
            { label: 'Date', key: 'date', checked: date },
        ];

        return headers.filter((header) => header.checked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const URL = process.env.REACT_APP_API_URL;
        const token_return = await fetchAccessToken();

        try {
            const response = await axios.post(`${URL}/api/v1/history/tocsv`, {
                name,
                color,
                barcode,
                stok,
                stoknow,
                action,
                date,
                datefrom,
                dateuntil

            },
                {
                    headers: {
                        Authorization: `Bearer ${token_return}`,
                    },
                });

            const message = response.data.message;
            const datares = response.data.data;
            setFilename(response.data.fileName);
            setSuccess(true);
            setData(datares);
            successMessage(message);


        } catch (error) {
            const error_message = error.response.data.message;
            errorMessage(error_message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (success > 0) {
            document.getElementById("csv-download-link").click();
            setSuccess(false);
        }
    }, [success])

    useEffect(() => {
        if (loading) {
            loadingbar();
        }
    }, [loading])

    function loadingbar() {
        return <Spinner animation="border" />;
    }


    return (
        <Modal
            show={showCsv}
            onHide={() => setShowCsv(false)}
            centered
            style={{ zIndex: 9999 }}
            size="lg">

            <Modal.Header closeButton>
                <Modal.Title>
                    Export CSV
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mx-3 mb-3" controlId="name">
                        <Form.Check
                            type="checkbox"
                            label="Nama"
                            onChange={(e) => setName(e.target.checked)}
                        />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="color">
                        <Form.Check
                            type="checkbox"
                            label="Color"
                            onChange={(e) => setColor(e.target.checked)}
                        />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="barcode">
                        <Form.Check
                            type="checkbox"
                            label="Barcode"
                            onChange={(e) => setBarcode(e.target.checked)}
                        />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="stok">
                        <Form.Check
                            type="checkbox"
                            label="Stok"
                            onChange={(e) => setStok(e.target.checked)}
                        />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="stok_now">
                        <Form.Check
                            type="checkbox"
                            label="Stok Terbaru"
                            onChange={(e) => setStoknow(e.target.checked)}
                        />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="action">
                        <Form.Check
                            type="checkbox"
                            label="Action"
                            onChange={(e) => setAction(e.target.checked)}
                        />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="date">
                        <Form.Check
                            type="checkbox"
                            label="Tanggal"
                            onChange={(e) => setDate(e.target.checked)}
                        />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="datefrom">
                        <Form.Label>Dari Tanggal</Form.Label>
                        <Form.Control
                            type="date"
                            label="Tanggal Dari"
                            onChange={(e) => setDatefrom(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mx-3 mb-3" controlId="dateuntil">
                        <Form.Label>Sampai Tanggal</Form.Label>
                        <Form.Control
                            type="date"
                            label="Tanggal Sampai"
                            onChange={(e) => setDateuntil(e.target.value)}
                        />
                    </Form.Group>
                    <hr />


                    <Button type="submit" disabled={loading} className="btn btn-primary w-50 text-center" variant="outline-info" style={{ borderRadius: 20 }}>
                        {loading ? <>{loadingbar()}</> : <>Submit</>}
                    </Button>


                </Form>
                <CSVLink
                    id="csv-download-link"
                    data={data}
                    headers={generateHeaders()}
                    filename={filename}
                    separator={";"}
                    hidden>
                    Download
                </CSVLink>
            </Modal.Body>

        </Modal>
    )
}

export default Csv_history_stok
