import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { Row, Dropdown, DropdownButton, Button, Col, Container, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Update_stok from "./product_components/update_stok";
import Edit_product from "./product_components/edit_product";
import Delete_product from "./product_components/delete_product";
import Add_product from "./product_components/add_product";
import Csv_product from "./product_components/csv_product";


const Product = () => {
  const [token, setToken] = useState("");
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [role, setRole] = useState("");
  const URL = process.env.REACT_APP_API_URL;
  const [refresh, setRefresh] = useState(0);
  const [showCsv, setShowCsv] = useState(false);


  const navigate = useNavigate();
  const data = {
    columns: [
      {
        label: 'No',
        field: 'no',
        sort: 'asc',
        width: 150
      },
      {
        label: 'ID',
        field: 'id',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Nama',
        field: 'nama',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Warna',
        field: 'warna',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Barcode',
        field: 'barcode',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Stok',
        field: 'stok',
        sort: 'asc',
        width: 100
      },
      ...(role === 'owner' || role === 'admin' ? [
        {
          label: 'Harga Modal',
          field: 'price_modal',
          sort: 'asc',
          width: 100
        },] : []
      ),

      ...(role === 'owner'
        ? [
          {
            label: 'Harga Dos',
            field: 'price_dos',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Harga Lusin',
            field: 'price_lusin',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Harga Grosir',
            field: 'price_grosir',
            sort: 'asc',
            width: 100,
          },
        ]
        : []),
      ...(role === 'owner' || role === 'admin' ? [
        {
          label: 'Total Harga',
          field: 'price_total',
          sort: 'asc',
          width: 100
        },] : []
      ),
      {
        label: 'Action',
        field: 'action',
        sort: 'asc',
        width: 100
      }
    ],
    rows: product.map((productItem, index) => ({
      no: index + 1,
      id: productItem.id,
      nama: productItem.name,
      warna: productItem.color,
      barcode: productItem.barcode,
      stok: productItem.stok,
      price_modal: productItem.price_modal.toLocaleString(),
      price_dos: productItem.price_dos.toLocaleString(),
      price_lusin: productItem.price_lusin.toLocaleString(),
      price_grosir: productItem.price_grosir.toLocaleString(),
      price_total: (productItem.price_modal * productItem.stok).toLocaleString(),
      action: (
        <DropdownButton size='sm' variant="info" id="dropdown-basic-button" title="Dropdown button">
          <Dropdown.Item onClick={() => handleInfoClick(productItem.id, "stok")}>Stok</Dropdown.Item>
          {(role === "admin" || role === "owner") && (
            <Dropdown.Item onClick={() => handleInfoClick(productItem.id, "edit")}>Edit</Dropdown.Item>
          )}
          {role === "owner" && (
            <Dropdown.Item onClick={() => handleInfoClick(productItem.id, "delete")} >Hapus</Dropdown.Item>
          )}
        </DropdownButton>
      )
    }))
  };



  const fetchAccessToken = async () => {
    try {
      const response = await axios.post(`${URL}/api/v1/token/`);
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setRole(decoded.permissions[0]);
      return response.data.accessToken;
    } catch (error) {
      setError("Failed to fetch access token. Redirecting...");
      navigate("/login");
    }
  };

  const handleInfoClick = (productId, action) => {
    // const selectedProduct1 = product.find((item) => item.id === productId);
    setSelectedProductId(productId);
    if (action === "stok") {
      setShowForm(true);
    } else if (action === "edit") {
      setShowEdit(true);
    } else if (action === "delete") {
      setShowDelete(true);
    } else if (action === "add") {
      setShowAdd(true);
    } else if (action === "csv") {
      setShowCsv(true);
    }
  };

  const successMessage = (success_message) => {
    toast.success(success_message, {
      position: toast.POSITION.TOP_RIGHT
    });
  };
  const errorMessage = (error_Message) => {
    toast.error(error_Message, {
      position: toast.POSITION.TOP_RIGHT
    });
  };


  const fetchProduct = async () => {
    try {
      const response = await axios.get(`${URL}/api/v1/product/getall`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setProduct(response.data.data);
      setLoading(false);

    } catch (error) {
      setError("Failed to fetch product data. Redirecting...");
      if (error.response.status === 403) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    fetchAccessToken();
  }, []);

  useEffect(() => {
    if (token) {
      fetchProduct();
    }
  }, [token, refresh]);

  const calculateTotalHarga = () => {
    return product.reduce((total, productItem) => {
      return total + (productItem.price_modal * productItem.stok);
    }, 0);
  };

  useEffect(() => {
    if (loading) {
      loadingbar();
    }
  }, [loading])

  function loadingbar() {
    return (
      <div className="d-flex justify-content-center align-items-center" style={styles.container}>
        <Spinner animation="border" />
      </div>)

  }

  const styles = {
    container: {
      height: '100vh', // Full height of the viewport
    },
  };


  return (
    <Container className="container mt-5">
      <ToastContainer />
      {loading ? (
       loadingbar()
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div>

          <Row>
            {(role === "admin" || role === "owner") && (
              <Col>
                <Button size='sm' variant="info" onClick={() => handleInfoClick(null, "add")}>
                  Tambah
                </Button>
                <Button size='sm' variant="info" onClick={() => handleInfoClick(null, "csv")}>
                  Csv
                </Button>

              </Col>
            )}

            <Col xs={6}></Col>

            {(role === "owner") && (
              <Col className="text-right">Total : {calculateTotalHarga().toLocaleString()}</Col>
            )}
          </Row>

          <MDBDataTable
            striped
            bordered
            small
            data={data}
            responsive
          />

          <Update_stok fetchAccessToken={fetchAccessToken} selectedProductId={selectedProductId} successMessage={successMessage} errorMessage={errorMessage} setShowForm={setShowForm} showForm={showForm} product={product} refresh={refresh} setRefresh={setRefresh} role={role} />
          <Edit_product fetchAccessToken={fetchAccessToken} showEdit={showEdit} setShowEdit={setShowEdit} selectedProductId={selectedProductId} product={product} successMessage={successMessage} errorMessage={errorMessage} refresh={refresh} setRefresh={setRefresh} role={role} />
          <Delete_product fetchAccessToken={fetchAccessToken} showDelete={showDelete} setShowDelete={setShowDelete} selectedProductId={selectedProductId} product={product} successMessage={successMessage} errorMessage={errorMessage} refresh={refresh} setRefresh={setRefresh} />
          <Add_product fetchAccessToken={fetchAccessToken} showAdd={showAdd} setShowAdd={setShowAdd} successMessage={successMessage} errorMessage={errorMessage} refresh={refresh} setRefresh={setRefresh} role={role} />
          <Csv_product fetchAccessToken={fetchAccessToken} showCsv={showCsv} setShowCsv={setShowCsv} successMessage={successMessage} errorMessage={errorMessage} />
        </div>
      )}
    </Container>
  );
};

export default Product;
