import React, { useState } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { Button, InputGroup, Modal } from "react-bootstrap";




const Update_stok = ({ fetchAccessToken, selectedProductId, errorMessage, successMessage, setShowForm, showForm, product, refresh, setRefresh, role }) => {
  const [action, setAction] = useState("");
  const [stok, setStok] = useState(0);
  const URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token_return = await fetchAccessToken();
    // Get the selected action and stok values from the form
    //   const action = e.target.action.value;
    //   const stok = e.target.stok.value;

    // Ensure both action and stok are selected
    if (!action || !stok) {
      return;
    }

    try {
      // Make a POST request to your API endpoint with the action and stok values
      const response = await axios.patch(
        `${URL}/api/v1/product/stok/${selectedProductId}`,
        {
          stok: parseFloat(stok),
          action,
        },
        {
          headers: {
            Authorization: `Bearer ${token_return}`,
          },
        }
      );
      const message = response.data.message;
      // Handle the response as needed (e.g., show a success message)
      successMessage(message);
      setAction("");
      setStok(0);
      setRefresh(refresh + 1);
      // Close the modal after a successful update
      setShowForm(false);
    } catch (error) {
      // Handle the error (e.g., display an error message)
      const error_message = error.response.data.message;
      errorMessage(error_message);
    }

  };

  return (
    <Modal
      show={showForm}
      onHide={() => setShowForm(false)}
      centered // Tengahkan modal di layar
      style={{ zIndex: 9999 }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title >
          Update Stok{" "}
          {selectedProductId &&
            product.find((item) => item.id === selectedProductId)?.name}
        </Modal.Title>
      </Modal.Header >
      <Modal.Body  >
        <Form onSubmit={handleSubmit} className='text-center'>
          <InputGroup className="mb-3">
            <InputGroup.Text>Stok Sekarang</InputGroup.Text>
            <Form.Control aria-label="Stok" value={product.find((item) => item.id === selectedProductId)?.stok} readOnly />
          </InputGroup>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Select name="action" aria-label="Default select example" onChange={(e) => setAction(e.target.value)}>
              <option>Action</option>
              {role === "admin" && <option value="masuk">+</option>}
              {role === "user" && <option value="keluar">-</option>}
              {role === "owner" && (
                <>
                  <option value="masuk">+</option>
                  <option value="keluar">-</option>
                </>
              )}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control name="stok" type="text" placeholder="Quantity" onChange={(e) => setStok(e.target.value)} />
          </Form.Group>
          <Button type="submit" className="btn btn-primary w-50 " variant="outline-info" style={{ borderRadius: 20 }}>
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>


  );
};

export default Update_stok
