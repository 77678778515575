import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const Edit_product = ({ showEdit, setShowEdit, successMessage, errorMessage, selectedProductId, product, fetchAccessToken, refresh, setRefresh, role }) => {
    const [name, setName] = useState('');
    const [color, setColor] = useState('');
    const [barcode, setBarcode] = useState('');
    const [stok, setStok] = useState(0);
    const [price_modal, setPrice_modal] = useState(0);
    const [price_dos, setPrice_dos] = useState(0);
    const [price_lusin, setPrice_lusin] = useState(0);
    const [price_grosir, setPrice_grosir] = useState(0);

    const selectedProduct = product.find((item) => item.id === selectedProductId);

    useEffect(() => {
        if (selectedProduct) {
            setName(selectedProduct.name || '');
            setColor(selectedProduct.color || '');
            setBarcode(selectedProduct.barcode || '');
            setStok(selectedProduct.stok || 0);
            setPrice_modal(selectedProduct.price_modal || 0);
            setPrice_dos(selectedProduct.price_dos || 0);
            setPrice_lusin(selectedProduct.price_lusin || 0);
            setPrice_grosir(selectedProduct.price_grosir || 0);
        }
    }, [selectedProduct]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const URL = process.env.REACT_APP_API_URL;
        const token_return = await fetchAccessToken();

        // Create a data object with the fields that have changed
        const data = {};
        if (name !== selectedProduct.name) {
            data.name = name;
        }
        if (color !== selectedProduct.color) {
            data.color = color;
        }
        if (barcode !== selectedProduct.barcode) {
            data.barcode = barcode;
        }
        if (stok !== selectedProduct.stok) {
            data.stok = parseFloat(stok);
        }
        if (price_modal !== selectedProduct.price_modal) {
            data.price_modal = parseFloat(price_modal);
        }
        if (price_dos !== selectedProduct.price_dos) {
            data.price_dos = parseFloat(price_dos);
        }
        if (price_lusin !== selectedProduct.price_lusin) {
            data.price_lusin = parseFloat(price_lusin);
        }
        if (price_grosir !== selectedProduct.price_grosir) {
            data.price_grosir = parseFloat(price_grosir);
        }


        if (Object.keys(data).length === 0) {
            errorMessage("Tidak ada yang diubah");
            return;
        }

        try {
            const response = await axios.patch(`${URL}/api/v1/product/edit/${selectedProductId}`, data, {
                headers: {
                    Authorization: `Bearer ${token_return}`,
                },
            });

            const message = response.data.message;
            successMessage(message);
            setShowEdit(false);
            setName('');
            setColor('');
            setBarcode('');
            setStok('');
            setPrice_modal('');
            setPrice_dos('');
            setPrice_lusin('');
            setPrice_grosir('');
            setRefresh(refresh + 1);

        } catch (error) {
            const error_message = error.response.data.message;
            errorMessage(error_message);
        }
    };

    return (
        <Modal
            show={showEdit}
            onHide={() => setShowEdit(false)}
            centered
            style={{ zIndex: 9999 }}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit {selectedProduct && selectedProduct.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}  >
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Nama</Form.Label>
                        <Form.Control name="name" type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="color">
                        <Form.Label>Warna</Form.Label>
                        <Form.Control name="color" type="text" placeholder="Color" value={color} onChange={(e) => setColor(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="stok">
                        <Form.Label>Stok</Form.Label>
                        <Form.Control name="stok" type="number" placeholder="Stok" value={stok} onChange={(e) => setStok(e.target.value)} disabled={role !== "owner"} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="price_modal">
                        <Form.Label>Harga Modal</Form.Label>
                        <Form.Control name="price_modal" type="number" placeholder="Harga Modal" value={price_modal} onChange={(e) => setPrice_modal(e.target.value)} />
                    </Form.Group>
                    {role === "owner" && (
                        <Form.Group className="mb-3" controlId="price_dos">
                            <Form.Label>Harga Dos</Form.Label>
                            <Form.Control name="price_dos" type="number" placeholder="Harga Dos" value={price_dos} onChange={(e) => setPrice_dos(e.target.value)} />
                        </Form.Group>
                    )}
                    {role === "owner" && (
                        <Form.Group className="mb-3" controlId="price_lusin">
                            <Form.Label>Harga Lusin</Form.Label>
                            <Form.Control name="price_lusin" type="number" placeholder="Harga Lusin" value={price_lusin} onChange={(e) => setPrice_lusin(e.target.value)} />
                        </Form.Group>
                    )}
                    {role === "owner" && (

                        <Form.Group className="mb-3" controlId="price_grosir">
                            <Form.Label>Harga Grosir</Form.Label>
                            <Form.Control name="price_grosir" type="number" placeholder="Harga Grosir" value={price_grosir} onChange={(e) => setPrice_grosir(e.target.value)} />
                        </Form.Group>
                    )}

                    <Form.Group className="mb-3" controlId="barcode">
                        <Form.Label>Barcode</Form.Label>
                        <Form.Control name="barcode" type="text" placeholder="Barcode" value={barcode} onChange={(e) => setBarcode(e.target.value)} />
                    </Form.Group>
                    <Button type="submit" className="btn btn-primary w-50 text-center" variant="outline-info" style={{ borderRadius: 20 }}>
                        Submit
                    </Button>

                </Form>
            </Modal.Body>
        </Modal>
    );
};
export default Edit_product;
