import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Row, Button, Spinner } from "react-bootstrap";
import { ToastContainer,toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import { format } from 'date-fns';
import jwt_decode from 'jwt-decode';
import History_view_modal from "./modal/history_view_modal";



const History = () => {
    const URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [role, setRole] = useState("");
    const [show, setShow] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);


    
    const rows = history.map((item, index) => {
        const no = index + 1;
        // const user = item.user ? item.user.username : null;
        // const displayUsername = user ? user : "user deleted";
        const action = item.description;
        const nama = item.name;
        const warna = item.color;
        const barcode = item.barcode;
        const stok = item.stok;
        const price_modal = item.price_modal;
        const price_dos = item.price_dos;
        const price_lusin = item.price_lusin;
        const price_grosir = item.price_grosir;


          
      
        // Repeat similar logic for other fields (tanggal)
      
        return {
          no,
          // user: displayUsername,
          action,
          nama,
          warna,
          barcode,
          stok,
          tanggal: format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm'),
          price_modal,
          price_dos,
          price_lusin,
          price_grosir,

          view: (
            <Button size='sm' variant="info" onClick={() => showmodal(item.id)}>
            View
          </Button>
          )
        };
      });

    const data = {
        columns: [
            {
                label: "No",
                field: "no",
                sort: "asc",
                width: 150,
            },
            // {
            //     label: "User",
            //     field: "user",
            //     sort: "asc",
            //     width: 270,
            // },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 200,
            },
            {
                label: "Nama",
                field: "nama",
                sort: "asc",
                width: 200,
            },
            {
                label: "Warna",
                field: "warna",
                sort: "asc",
                width: 100,
            },
            {
                label: "Barcode",
                field: "barcode",
                sort: "asc",
                width: 150,
            },
            {
                label: "Stok",
                field: "stok",
                sort: "asc",
                width: 100,
            },
            {
                label: "Harga Modal",
                field: "price_modal",
                sort: "asc",
                width: 100,
            },
            ...(role === 'owner'
            ? [
                {
                  label: 'Harga Dos',
                  field: 'price_dos',
                  sort: 'asc',
                  width: 100,
                },
                {
                  label: 'Harga Lusin',
                  field: 'price_lusin',
                  sort: 'asc',
                  width: 100,
                },
                {
                  label: 'Harga Grosir',
                  field: 'price_grosir',
                  sort: 'asc',
                  width: 100,
                },
              ]
            : []),
            {
              label: "Tanggal",
              field: "tanggal",
              sort: "asc",
              width: 100,
          },
          {
            label: "view",
            field: "view",
            sort: "asc",
            width: 100,
          }
        ],
        rows
    };

    const showmodal = (id) => {
        setSelectedProductId(id);
        setShow(true);
    }

    const successMessage = (success_message) => {
      toast.success(success_message, {
        position: toast.POSITION.TOP_RIGHT
      });
    };
    const errorMessage = (error_Message) => {
      toast.error(error_Message, {
        position: toast.POSITION.TOP_RIGHT
      });
    };



    const fetchAccessToken = async () => {
        try {
            const response = await axios.post(`${URL}/api/v1/token/`);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.permissions[0]);
            return response.data.accessToken;
        } catch (error) {
            setError("Failed to fetch access token. Redirecting...");
            navigate("/login");
        }
    };

    const fetchHistory = async () => {
        const URL = process.env.REACT_APP_API_URL;
        const token = await fetchAccessToken();
        try {
            const response = await axios.get(`${URL}/api/v1/history/getall`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data.data;
            setHistory(data);
            setLoading(false);
        } catch (error) {
            setError("Failed to fetch history data. Redirecting...");
        }
    };


    useEffect(() => {
        fetchHistory();
    }, []);

    useEffect(() => {
      if (loading) {
          loadingbar();
      }
  }, [loading])

  function loadingbar() {
      return (
          <div className="d-flex justify-content-center align-items-center" style={styles.container}>
              <Spinner animation="border" />
          </div>)

  }

  const styles = {
      container: {
        height: '100vh', // Full height of the viewport
      },
    };
  



  return (
    <>
    <Row className="container mt-5">
    <ToastContainer />
    {loading ? (
      loadingbar()
    ) : error ? (
      <p>{error}</p>
    ) : (
      <div>
        <MDBDataTable
          striped
          bordered
          small
          responsive
          data={data}
        />
      </div>
    )}
  </Row>
  
      <History_view_modal show={show} setShow={setShow} selectedProductId={selectedProductId} setSelectedProductId={setSelectedProductId} fetchAccessToken={fetchAccessToken} successMessage={successMessage} errorMessage={errorMessage}/>
    </>


  )
}

export default History
