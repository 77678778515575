import React, { useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import axios from 'axios';

const Add_product = ({ showAdd, setShowAdd, successMessage, errorMessage, fetchAccessToken,  setRefresh, refresh }) => {
    const [username, setUsername] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const URL = process.env.REACT_APP_API_URL;
        const token = await fetchAccessToken();
        try {
            const response = await axios.post(`${URL}/api/v1/user/add`, {
                username,
                roles : role,
                password,
                confPassword
            },{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const message = response.data.message;
            successMessage(message);
            setShowAdd(false);
            setUsername('');
            setRole('');
            setPassword('');
            setConfPassword('');
            setRole('');
            setRefresh(refresh + 1);
        } catch (error) {
            const error_message = error.response.data.message;
            errorMessage(error_message);
        }
    }

    return (
        <Modal
            show={showAdd}
            onHide={() => setShowAdd(false)}
            centered
            style={{ zIndex: 9999 }}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Add Product
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}  >
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Username</Form.Label>
                        <Form.Control name="username" type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="color">
                        <Form.Label>Password</Form.Label>
                        <Form.Control name="password" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="stok">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control name="confPassword" type="password" placeholder="Confirm Password" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Select
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        aria-label="Default select example">
                        <option>Role</option>
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                        <option value="owner">Owner</option>
                    </Form.Select>
                    <Button type="submit" className="btn btn-primary w-50 text-center" variant="outline-info" style={{ borderRadius: 20 }}>
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default Add_product
