import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

const Delete_product = ({ fetchAccessToken, showDelete, setShowDelete, selectedProductId, product, successMessage, errorMessage,  refresh, setRefresh }) => {

    const handleClose = () => {
        setShowDelete(false);
    }

    const handleDelete = async (e) => {
        e.preventDefault();

        const token_return = await fetchAccessToken();
        try {
            const URL = process.env.REACT_APP_API_URL;
            const response = await axios.delete(`${URL}/api/v1/product/delete/${selectedProductId}`, {
                headers: {
                    Authorization: `Bearer ${token_return}`,
                },
            });
            const message = response.data.message;
            successMessage(message);
            setShowDelete(false);
            setRefresh(refresh + 1);
        } catch (error) {
            const error_message = error.response.data.message;
            errorMessage(error_message);
        }
    }


    return (
        <Modal
            show={showDelete}
            onHide={() => setShowDelete(false)}
            centered
            style={{ zIndex: 9999 }}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title >
                    Delete{" "}
                    {selectedProductId &&
                        product.find((item) => item.id === selectedProductId)?.name}
                </Modal.Title>
            </Modal.Header >
            <Modal.Body>
                <p>Yakin ingin menghapus product ini?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Batal
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Hapus
                </Button>
            </Modal.Footer>

        </Modal>
    )
}

export default Delete_product
