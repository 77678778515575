import axios from "axios";
import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Col, Container } from 'react-bootstrap'
import { format } from 'date-fns';


const History_view_modal = ({ show, setShow, selectedProductId, fetchAccessToken, errorMessage }) => {

    const [history, setHistory] = useState({});

    const getHistory = async () => {
        const URL = process.env.REACT_APP_API_URL;
        const token_return = await fetchAccessToken();

        try {
            const response = await axios.get(`${URL}/api/v1/history/getone/${selectedProductId}`, {
                headers: {
                    Authorization: `Bearer ${token_return}`,
                },
            });

            const data = response.data;
            console.log(data);
            setHistory(data.data);
        } catch (error) {
            const error_message = error.response.data.message;
            errorMessage(error_message);
        }

    }

    useEffect(() => {
        if (selectedProductId !== null) {
            getHistory();
        }
    }, [selectedProductId])


    const hidemodal = () => {
        setShow(false);
    }

    return (
        <Modal
            show={show}
            onHide={() => hidemodal()}
            centered
            style={{ zIndex: 9999 }}
            size="xl"
        >


            <Modal.Header closeButton>
                <Modal.Title>
                    {
                        `${history &&
                            history.history_details &&
                            history.history_details.length > 1
                            ? history.history_details[1].name
                            : history && history.history_details
                                ? history.history_details[0]?.name || null
                                : null
                        }`
                    }
                </Modal.Title>
            </Modal.Header>
            {history?.history_details?.length > 1 && (
                <Modal.Body>
                    <Container>
                        <Form  >
                            <Row className="mb-5">
                                <Col xs={2}>
                                    <p style={{ fontSize: "30px" }}>{history.description}</p>
                                </Col>
                                <Col xs={8}><p className="text-center" style={{ fontSize: '30px' }}>{format(new Date(history.createdAt), 'yyyy-MM-dd HH:mm')}</p></Col>
                                <Col xs={2}>
                                    <p style={{ fontSize: '30px' }} className="text-right">{history.user.username}</p>
                                </Col>

                            </Row>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Nama</Form.Label>
                                <Row>
                                    <Col xs={12} className="mb-3"><Form.Control className="is-valid" name="name" type="text" placeholder="Name" value={history.history_details[1].name} /></Col>
                                    <Col xs={12}><Form.Control name="name" type="text" placeholder="Name" value={history.history_details[0].name} /></Col>
                                </Row>
                            </Form.Group>
                            <Row>
                                <Col><Form.Group className="mb-3" controlId="color">
                                    <Form.Label>Warna</Form.Label>
                                    <Row>
                                        <Col><Form.Control name="color" type="text" placeholder="Color" className="is-valid" value={history.history_details[1].color} /></Col>
                                        <Col><Form.Control name="color" type="text" placeholder="Color" value={history.history_details[0].color} /></Col>
                                    </Row>
                                </Form.Group></Col>
                                <Col><Form.Group className="mb-3" controlId="stok">
                                    <Form.Label>Stok</Form.Label>
                                    <Row>
                                        <Col><Form.Control name="stok" type="number" placeholder="Stok" className="is-valid" value={history.history_details[1].stok} /></Col>
                                        <Col><Form.Control name="stok" type="number" placeholder="Stok" value={history.history_details[0].stok} /></Col>
                                    </Row>
                                </Form.Group></Col>
                            </Row>
                            <Row>
                                <Col><Form.Group className="mb-3" controlId="price_modal">
                                    <Form.Label>Harga Modal</Form.Label>
                                    <Row>
                                        <Col><Form.Control name="price_modal" type="number" className="is-valid" placeholder="Harga Modal" value={history.history_details[1].price_modal} /></Col>
                                        <Col><Form.Control name="price_modal" type="number" placeholder="Harga Modal" value={history.history_details[0].price_modal} /></Col>

                                    </Row>
                                </Form.Group></Col>
                                <Col><Form.Group className="mb-3" controlId="price_dos">
                                    <Form.Label>Harga Dos</Form.Label>
                                    <Row>
                                        <Col><Form.Control name="price_dos" type="number" className="is-valid" placeholder="Harga Dos" value={history.history_details[1].price_dos} /></Col>
                                        <Col><Form.Control name="price_dos" type="number" placeholder="Harga Dos" value={history.history_details[0].price_dos} /></Col>
                                    </Row>
                                </Form.Group></Col>
                            </Row>
                            <Row>
                                <Col><Form.Group className="mb-3" controlId="price_lusin">
                                    <Form.Label>Harga Lusin</Form.Label>
                                    <Row>
                                        <Col><Form.Control name="price_lusin" type="number" className="is-valid" placeholder="Harga Lusin" value={history.history_details[1].price_lusin} /></Col>
                                        <Col><Form.Control name="price_lusin" type="number" placeholder="Harga Lusin" value={history.history_details[0].price_lusin} /></Col>

                                    </Row>
                                </Form.Group></Col>
                                <Col><Form.Group className="mb-3" controlId="price_grosir">
                                    <Form.Label>Harga Grosir</Form.Label>
                                    <Row>
                                        <Col><Form.Control name="price_grosir" type="number" className="is-valid" placeholder="Harga Grosir" value={history.history_details[1].price_grosir} /></Col>
                                        <Col><Form.Control name="price_grosir" type="number" placeholder="Harga Grosir" value={history.history_details[0].price_grosir} /></Col>

                                    </Row>
                                </Form.Group></Col>
                            </Row>
                            <Form.Group className="mb-3" controlId="barcode">
                                <Form.Label>Barcode</Form.Label>
                                <Row>
                                    <Col><Form.Control name="barcode" type="text" className="is-valid" placeholder="Barcode" value={history.history_details[1].barcode} /></Col>
                                    <Col><Form.Control name="barcode" type="text" placeholder="Barcode" value={history.history_details[0].barcode} /></Col>

                                </Row>
                            </Form.Group>

                        </Form>
                    </Container>
                </Modal.Body>
            )}
            {history?.history_details?.length === 1 && (
                <Modal.Body>
                    <Container>
                        <Form  >
                        <Row className="mb-5">
                                <Col xs={2}>
                                    <p style={{ fontSize: "30px" }}>{history.description}</p>
                                </Col>
                                <Col xs={8}><p className="text-center" style={{ fontSize: '30px' }}>{format(new Date(history.createdAt), 'yyyy-MM-dd HH:mm')}</p></Col>
                                <Col xs={2}>
                                    <p style={{ fontSize: '30px' }} className="text-right">{history.user.username}</p>
                                </Col>

                            </Row>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Nama</Form.Label>
                                <Form.Control name="name" type="text" placeholder="Name" value={history.history_details[0].name} />
                            </Form.Group>
                            <Row>
                                <Col><Form.Group className="mb-3" controlId="color">
                                    <Form.Label>Warna</Form.Label>
                                    <Form.Control name="color" type="text" placeholder="Color" value={history.history_details[0].color} />
                                </Form.Group></Col>
                                <Col><Form.Group className="mb-3" controlId="stok">
                                    <Form.Label>Stok</Form.Label>
                                    <Form.Control name="stok" type="number" placeholder="Stok" value={history.history_details[0].stok} />
                                </Form.Group></Col>
                            </Row>
                            <Row>
                                <Col><Form.Group className="mb-3" controlId="price_modal">
                                    <Form.Label>Harga Modal</Form.Label>
                                    <Form.Control name="price_modal" type="number" placeholder="Harga Modal" value={history.history_details[0].price_modal} />
                                </Form.Group></Col>
                                <Col><Form.Group className="mb-3" controlId="price_dos">
                                    <Form.Label>Harga Dos</Form.Label>
                                    <Form.Control name="price_dos" type="number" placeholder="Harga Dos" value={history.history_details[0].price_dos} />
                                </Form.Group></Col>
                            </Row>
                            <Row>
                                <Col><Form.Group className="mb-3" controlId="price_lusin">
                                    <Form.Label>Harga Lusin</Form.Label>
                                    <Form.Control name="price_lusin" type="number" placeholder="Harga Lusin" value={history.history_details[0].price_lusin} />
                                </Form.Group></Col>

                                <Col><Form.Group className="mb-3" controlId="price_grosir">
                                    <Form.Label>Harga Grosir</Form.Label>
                                    <Form.Control name="price_grosir" type="number" placeholder="Harga Grosir" value={history.history_details[0].price_grosir} />
                                </Form.Group></Col>
                            </Row>
                            <Form.Group className="mb-3" controlId="barcode">
                                <Form.Label>Barcode</Form.Label>
                                <Form.Control name="barcode" type="text" placeholder="Barcode" value={history.history_details[0].barcode} />
                            </Form.Group>

                        </Form>
                    </Container>
                </Modal.Body>

            )}

        </Modal>
    )
}

export default History_view_modal
